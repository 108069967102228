@import '../../styles/vars.scss';

.adminDashboard__container {
      width: 100%;
      @include container-padding;
      
      .adminDashboard__content {
            display: flex;
            flex-direction: column;
            gap: clamp(20px, 2vw, 50px);
            margin-inline: auto;
            max-width: $container-width;

            .adminToolsPanel {
                  margin-bottom: 2rem;
                  padding: 1.2rem;
                  background-color: #f9f9f9;
                  border-radius: 0.5rem;
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

                  .adminToolBox {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 3rem;
                        gap: 2rem;

                        .adminToolBox__controllers {
                              display: flex;
                              flex-direction: column;
                              gap: 1.25rem;
                        }
                  }

                  .adminTool__newsCreatorBox {
                        display: grid;
                        width: 100%;
                        grid-template-columns: auto 2fr;
                        gap: 1.25rem;

                        @media (max-width: 600px) {
                              display: flex;
                              flex-direction: column;
                        }
                        
                        & > :first-child {
                              grid-row: 2 span;
                        }

                        & > :nth-child(2) {
                              display: grid;
                              align-items: end;
                        }

                        & > :last-child {
                              align-items: start;
                        }
                  }
                  
            }

            .alertText {
                  color: $error-color;
            }
      }
}
.subscriptionsTable {
      width: 100%;
      border-collapse: collapse;
      font-size: 1rem;
      font-family: 'Arial', sans-serif;
      background-color: #ffffff;
  
      th, td {
          padding: 0.75rem 1.5rem;
          border: 0.0625rem solid #ddd;
      }
  
      th {
          background-color: #333;
          color: #ffffff;
          text-align: left;
          font-weight: 600;
          font-size: 1.1rem;
      }
  
      tbody tr:nth-child(even) {
          background-color: #f9f9f9;
      }
  
      tbody tr:hover {
          background-color: #f1f1f1;
      }
  
      td {
          position: relative;
          font-size: 0.9rem;
      }
  
      @media (max-width: 600px) {
            display: block;
            width: 100%;
            overflow-x: auto;

            thead {
                  display: none;
            }
      
            tbody {
                  background-color: transparent;
                  display: block;

                  tr {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1rem;
                        border: 2px solid #ddd;
                        padding: 0.75rem;
                        box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
      
                        td {
                              display: flex;
                              justify-content: space-between;
                              text-align: left;
                              padding: 0.5rem 1.5rem;
                              border: none;
                              padding: 0.5rem 0;
                        }
      
                        td::before {
                              content: attr(data-label);
                              position: static;
                              font-weight: bold;
                              white-space: nowrap;
                        }
                  }
            }
      }
}