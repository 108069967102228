@import '../../styles/vars.scss';

.gallery {
    display: flex;
    position: relative;
    width: 100%;

    &.singleImage {
        :global(.swiper-wrapper) {
            justify-content: center;
        }
    }
    
    .gallerySwiper {
        display: flex;
        width: 100%;
        height: clamp(250px, 200px + 5vw, 400px);
    }

    img {
        display: flex;
        flex: 1;
        border-radius: 20px;
        width: 95%;
        height: 100%;
        object-fit: cover;
        justify-content: center;
        margin-inline: auto;
        cursor: pointer;
    }

    .customNavigation {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 50%;
    }

    .prevButton,
    .nextButton {
        width: 2rem;
        height: 2rem;
        background-color: $primary-color;
        mask-size: cover;
        transition: all $transition-duration ease;
        cursor: pointer;

        &:disabled {
            opacity: 0;
            cursor: default;
        }
    }

    .prevButton {
        mask: url('../../assets/UI/arrows/swiperArrow.svg') no-repeat center;
        transform: rotate(180deg);
        margin-left: -2.5rem;
    }

    .nextButton {
        mask: url('../../assets/UI/arrows/swiperArrow.svg') no-repeat center;
        margin-right: -2.5rem;
    }

    :global(.swiper-pagination) {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 20px; 
    }

    :global(.swiper-pagination-bullet) {
        width: 0.8rem;
        height: 0.8rem;
        background-color: $semi-transparent-gray; 
        opacity: 1;
        transition: background-color $transition-duration ease;
    }

    :global(.swiper-pagination-bullet-active) {
        background-color: $yellow;
    }
}