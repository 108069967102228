@import '../../styles/vars.scss';

.auth__container {
    width: 100%;
    background-color: $background-gray;
    height: fit-content;
    padding: 150px 0;
    
    .auth__wrapper {
        display: flex;
        flex-direction: column;
        margin-inline: auto;
        width: clamp(300px, 220px + 12vw, 400px);

        form {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            gap: 0.75rem;
            margin-top: 1rem;
            border-radius: 20px;
            background-color: $background-white;

            .form__content {
                display: flex;
                flex-direction: column;
                gap: 0.75rem;
                padding: 1.5rem;
                padding-bottom: 0;
    
                .stepNumeration {
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: translateY(-50%) translateX(50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 5rem;
                    height: 5rem;
                    border: 0.6rem solid $background-gray;
                    border-radius: 50%;
                    
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                
                    span {
                        position: absolute;
                        color: $golden-olive;
                        font-size: 2rem;
                        font-weight: 600;
                    }
                }
    
                p {
                    color: #565656;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 155%;
                }
    
                .AuthLinks {
                    position: relative;
                    color: $light-gray-color;
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 0.04em;
                    cursor: pointer;
                    margin: 0 auto;
                    width: fit-content;
                    outline: none;
                    background: none;
                    border: none;
                    transition: color $transition-duration ease-out;
                    
                    &:hover {
                        color: $primary-color;
                    }
    
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        bottom: -2px;
                        transform: scaleX(0);
                        background-color: $light-gray-color;
                        transform-origin: center;
                        transition: all $transition-duration ease-out;
                        border-radius: 5px;
                    }
    
                    &:hover::before {
                        transform: scaleX(1);
                        transform-origin: center;
                        background-color: $primary-color;
                    } 
                }
            }
            .submit__container {
                display: flex;
                margin-top: auto;
                align-items: center;
                bottom: 0;
                width: 100%;
                padding-left: 1.5rem;
                // background-color: #565656;
                height: 4rem;

                .submitButton {
                    position: absolute;
                    right: -0.625rem;
                    border-radius: 60px;
                    border: 0.625rem solid $background-gray;
                }
            }
        }
    }
}