@import '../../styles/vars.scss';

.news__container {
    width: 100%;
    height: fit-content;
    @include container-padding;
    background: $background-gray;

    .news__content {
        margin-inline: auto;
        max-width: $container-width;
        width: 100%;
        
        h1 {
            font-size: 2.5rem;
            line-height: 100%;
            letter-spacing: 0.05em;
        }
        
        .news__items {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
}