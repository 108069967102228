.notification__container {
    position: fixed;
    top: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    @media (max-width: 600px) {
        width: 80vw;
    }
}

.notification {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 0.04rem;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    animation: slideDown 0.3s ease, fadeOut 0.3s ease 2s forwards;
}

.loading {
    display: flex;
    align-items: center;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #ffe26d;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.6rem;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(50px);
    }
}