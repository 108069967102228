@import '../../styles/vars.scss';

.allFilters__container {
      position: relative;
            
      .allFilters__button {
            display: flex;
            align-items: center;
            gap: 0.625rem;
            position: relative;

            border: none;
            outline: none;
            padding: 0.6rem 1.2rem;
            
            border-radius: 100px;
            background-color: #DCDCDC33;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 100%;
            cursor: pointer;
            transition: all $transition-duration ease;

            @media (max-width: 600px) {
                  font-size: 1rem;
                  background-color: transparent;
                  padding-bottom: 0;
            }

            span {
                  display: flex;
                  position: absolute;
                  top: -0.7rem;
                  right: 0.8rem;
                  font-size: 1rem;
                  width: 1.5rem;
                  height: 1.5rem;
                  color: $golden-olive;
                  text-align: center;
                  border-radius: 100%;
                  background-color: $focus-border-color-light;
                  align-items: center;
                  justify-content: center;
            }
              
            img {
                  width: 1.5rem;
                  height: 1.5rem;

                  @media (max-width: 600px) {
                        display: none;
                  }
            }
            
            @media (hover: hover) {
                  &:hover {
                        background-color: rgba(220, 220, 220, 0.5);
                  }
            }
      }

      .filters__config {
            position: absolute;
            transform: translateY(100%);
            bottom: -10px;
            right: 0;
            z-index: 2;
            width: clamp(300px, 220px + 35vw, 588px);
            
            .config__wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 1.5rem;
                  padding: 1.5rem;
                  right: 0;
                  border-radius: 20px;
                  background: #F2F3F5;
      
                  p {
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 100%;
                        text-align: left;
                  }
      
                  .filters__buttonsBox {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 0.75rem;
                        gap: 0.35rem;
                  }
                  
                  .filters__dropdownButtons {
                        display: flex;
                        flex-direction: column;
                        margin-top: 0.75rem;
                        gap: 0.35rem;
                  }
            }
      }
}