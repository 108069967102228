@import '../../styles/vars.scss';

.favorites__container {
      width: 100%;
      @include container-padding;
      
      .favorites__content {
            max-width: $container-width;
            margin-inline: auto;
            width: 100%;
      
            h1 {
                  margin-bottom: 1rem;
                  font-size: 2.5rem;
                  letter-spacing: 0.05em;
                  color: $primary-color;
            }
            
            .favorites__items {
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
            }
      }
}