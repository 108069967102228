@import '../../styles/vars.scss';

.questionnaire__container {
     width: 100%;
     @include container-padding;

     .questionnaire__content {
          margin: 0 auto;
          max-width: $container-width;

          h1 {
               font-size: 2.5rem;
               letter-spacing: 0.05em;
               line-height: 100%;

               @media (max-width: 768px) {
                    font-size: 2rem;
                    text-align: center;
               }
          }

          .questionnaire__items {
               display: flex;
               flex-direction: column;
               margin-top: 1rem;
               gap: 1rem;
          }

          .questionnaire__buttons {
               display: flex;
               justify-content: space-between;
               margin-top: 2rem;
          }
     }
}