@import '../../styles/vars.scss';

.privacyPolicy__container {
    width: 100%;
    @include container-padding;
    
    .privacyPolicy__content {
        margin-inline: auto;
        max-width: $container-width;

        h1 {
            font-size: 2.25rem;
            letter-spacing: -0.05em;
            margin-bottom: 10px;
        }
        h2 {
            font-size: 1.75rem;
            letter-spacing: -0.05em;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        p {
            font-size: 1rem;
            color: $primary-color;
        }

        ul {
            list-style: none;
        }
    }
}