@import '../../../styles/vars.scss';

.inputField {
    display: flex;
    align-items: center;
    position: relative;
    min-width: clamp(200px, 20vw, 350px);
    
    .input {
        width: 100%;
        border-radius: 0.75rem;
        padding: 1rem;
        line-height: 150%;
        font-size: 0.875rem;
        font-weight: 500;
        border: 2px solid $border-color;
        color: $primary-color;
        
        outline: none;
        cursor: pointer;
        transition: border-color $transition-duration ease;

        &::placeholder {
            color: $placeholder-color;
        }

        &:hover {
            border-color: $hover-border-color;
        }
        
        &:focus {
            border-color: $focus-border-color-light;
        }
        
        &[type='number']::-webkit-outer-spin-button,
        &[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .inputWrapper {
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
   
        .percentageSymbol {
            position: absolute;
            right: 0;
            padding: 1rem;
            font-size: 0.875rem;
            font-weight: 500;
            color: $icon-color-light;
        }
    }
    
    .selectWrapper {
        width: 100%;
        position: relative;
        display: inline-block;
        
        .input {
            appearance: none;
            transition: border-color $transition-duration ease;
            padding: 1rem 2rem 1rem 1rem;
            cursor: pointer;
            
            &::-ms-expand {
                display: none;
            }
        }

        .customArrow {
            position: absolute;
            top: 50%;
            right: 1rem;
            width: 0;
            height: 0;
            border-left: 0.4rem solid transparent;
            border-right: 0.4rem solid transparent;
            border-top: 0.4rem solid #565656;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }
   
    .textareaWrapper {
        flex: 1;
        position: relative;

        textarea {
            min-width: 200px;
            max-height: 120px;
            min-height: 100px;
            
            &.input {
                display: flex;
                overflow: auto;
                position: relative;
                width: 100%;
                height: auto;
                min-height: 100%;
                resize: none;
            }
        }
   
        .charCount {
            position: absolute;
            right: 15px;
            bottom: 10px;
            font-size: 0.75rem;
            font-weight: 500;
            color: #565656;
        }
    }
    
    .errorIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        background-color: #fff;
        transform: translateY(-50%);
        color: $error-icon-color;
        display: none;
    }
    
    .errorMessage {
        display: none;
    }

    &.error {
        .input {
            border-color: $error-border-color;
            
            &::placeholder {
                color: $error-placeholder-color;
            }
        }

        .errorIcon {
            display: block;
        }

        .errorMessage {
            position: absolute;
            display: block;
            color: $error-color;
            font-size: 0.75rem;
            bottom: -19px;
        }
    }
}