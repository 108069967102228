@import '../../styles/vars.scss';

.checkbox__container {
    display: flex;
    align-items: center;
    gap: 0.725rem;

    label {
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.04em;
        color: rgba(86, 86, 86, 1);
        cursor: pointer;
    }

    input {
        position: relative;
        border: 2px solid $light-gray-color;
        border-radius: 0.5rem;
        min-width: 1.5rem;
        width: min-content;
        height: 1.5rem;
        outline: none;
        appearance: none;
        transition: border-color $transition-duration ease;
        cursor: pointer;

        &:hover {
            border-color: #8d8d8d;
        }

        &:checked::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            
            transform: translate(-50%, -70%) rotate(-45deg);
            width: 0.875rem;
            height: 0.43rem;
            border-left: 2px solid #ffffff;
            border-bottom: 2px solid #ffffff;
        }

        &:checked {
            background-color: #f6d655;
            border-color: #f6d655;
        }
    }
}