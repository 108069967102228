.pagination {
     display: flex;
     margin-inline: auto;
     align-items: baseline;
     justify-content: center;
     position: relative;
     width: fit-content;

     &::before {
          content: "";
          position: absolute;
          bottom: 0;
          height: 1px;
          width: 100%;
          background: #DCDCDC;
     }

     .underlineAnimation {
               position: absolute;
               bottom: -1px;
               height: 3px;
               width: 2rem;
               background-color: #FFE26D;
               border-radius: 0.4rem;
               transition: left 0.3s, width 0.3s;
          }

          a {
               padding: 0.4rem 0.6rem 0.6rem ;
               height: 100%;
               text-align: center;
               position: relative;
               color: #1F1F1F;
               font-size: 1.3rem;
               font-weight: 400;
               line-height: normal;
               letter-spacing: 0.05em;
               transition: color 0.3s;

          &:hover {
               background-color: rgb(0, 0, 0, 0.08);
          }

          &.ActiveLink {
               color: #1F1F1F;
          }

          &.DisabledLink {
               color: #AEAEAE;
          }
     }
     .pagination__dots {
          padding: 5px 10px;
          color: #AEAEAE;
     }
}