.profileInput__container {
      position: relative;
      display: flex;
      align-items: center;

      input {
            width: 100%;
            border-radius: 0.875rem;
            padding: 1rem 1.2rem;
            background-color: rgba(217, 224, 236, 0.15);
            outline: none;
            border: 2px dashed transparent;
            font-weight: 500;
            font-size: 1.25rem;
            letter-spacing: 0.05em;
            color: #565656;
            transition: all 0.2s ease-in;


            &.editingInput {
                  border-color: #FFE26D;

                  &:hover {
                        border-color: #ffcc00;
                  }
            }

            &.ErrorInput {
                  border-color: #FF6464;
            
                  &::placeholder {
                      color: #FF6464;
                  }
            
                  &:hover {
                        border-color: #FF6464;
                  }

            }
      }
      
      .errorMessage {
            position: absolute;
            display: block;
            color: red;
            font-size: 0.75rem;
            bottom: -15px;
      }

      .profileHint {
            left: -20px;     
            border: 1px solid black; 
      }
}