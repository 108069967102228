.questionnaireNotification {
      position: fixed;
      bottom: 1rem;
      right: 1rem;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      padding: 1.5rem;
      background-color: white;
      border: 0.4rem solid #FFE26D;
      border-radius: 1.5rem;
      z-index: 1000;
      width: clamp(550px, 500px + 10vw, 650px);
      align-items: end;

      @media (max-width: 600px) {
            width: calc(100% - 2rem);
      }
      
      &.visible {
            animation: visibility 0.6s ease forwards;
      }

      &.invisible {
            animation: invisibility 0.6s ease forwards;
      }

      .closeNotification {
            position: absolute;
            display: flex;
            color: #7A6C31;
            background: transparent;
            border: none;
            font-size: 4rem;
            top: 10px;
            right: 30px;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                  color: #ffcc00;
            }
      }

      h1 {
            font-size: 2rem;
            letter-spacing: 0.05em;
            width: 100%;
            text-align: left;

            span {
                  color: #f6d655;
            }
      }

      p {
            font-weight: 500;
            font-size: 1rem;
            line-height: 160%;
            text-align: left;
      }
}
  
@keyframes visibility {
      0% { 
            transform: translateY(100px);
            opacity: 0;
      }
      100% {
            transform: translateY(0);
            opacity: 1;
      }
}

@keyframes invisibility {
      0% { 
            transform: translateY(0);
            opacity: 1;
      }
      100% {
            transform: translateY(100px);
            opacity: 0;
      }
}