@import '../../styles/vars.scss';

.questionnaireButton {
     display: flex;
     align-items: center;
     width: fit-content;
     gap: 1.375rem;
     justify-content: center;

     font-weight: 600;
     font-size: 1rem;
     letter-spacing: 0.07em;
     color: $border-gray;
     background-color: white;
     border: 2px solid $border-gray;
     border-radius: 2.5rem;

     transition: all 0.2s ease;
     cursor: pointer;
     padding: 0.3rem 0.3rem 0.3rem 1.5rem;

     &:hover {
          border-color: white;
          background-color: rgb(255, 200, 60);
          color: white;

          .cheeseUI::before {
               filter: brightness(5);
          }
     }

     span {
          text-align: center;
          margin-right: auto;
     }

     .cheeseUI {
          display: flex;
          align-items: center;
          position: relative;
          justify-content: flex-end;
          align-self: flex-end;
          margin-left: auto;

          img {
               width: 3rem;
               height: 3rem;
          }

          &::before {
               content: '';
               display: inline-block;
               position: absolute;
               width: 1.6rem;
               height: 0.85rem;
               background-image: url('../../assets/UI/arrows/arrowForQuestionnaire.svg');
               background-size: contain;
               background-repeat: no-repeat;
               background-position: center;
               left: 50%;
               transform: translateX(-50%);
               transition: filter $transition-duration ease;
          }
     }
}