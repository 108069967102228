@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import './vars.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;

    @media (max-width: 1380px) {
        font-size: 14px;
    }

    @media (max-width: 1200px) {
        font-size: 12px;
    }
}

body {
    width: 100%;
    height: 100%;
    font-family: "IBM Plex Sans", sans-serif;
    color: $primary-color
}

a {
    text-decoration: none;
}

.francheese__app {
    width: 100%;
    min-width: 360px;
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-top: clamp(60px, 20px + 5vw, 85px);
}