@import "../../../../styles/vars.scss";

.recommendedFranchises__container {
      width: 100%;
      @include container-padding;
      
      .recommendedFranchises__content {
            margin-inline: auto;
            max-width: $container-width;

            h1 {
                  font-size: 2.2rem;
                  margin-bottom: clamp(10px, 1vw, 20px);
            }
            
            @media (min-width: 640px) and (max-width: 768px) {
                  grid-template-columns: repeat(3, 1fr);
            }

            .recommendedFranchises__wrapper {
                  display: flex;
                  gap: clamp(10px, 1.5vw, 40px);
                  width: 100%;
                  
                  @media (max-width: 768px) {
                        flex-direction: column;
                  }
                  
                  .specialFranchise__container {
                        display: flex;
                        flex-direction: column;

                        .specialFranchise__card {
                              margin: 0 auto;
                              
                              @media (max-width: 500px) {
                                    width: 100%
                              }
                        }
                        
                        
                        .specialFranchise__infoBody {
                              display: flex;
                              gap: 0.6rem;
                              margin-top: clamp(10px, 1vw, 20px);

                              .specialFranchise__ownerImage {
                                    width: 3rem;
                                    height: 3rem;
                                    border-radius: 100%;
                                    cursor: pointer;
                                    object-fit: cover;
                              }

                              .specialFranchise__owner {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 0.2rem;
                                    p {
                                          font-size: 1.25rem;
                                          font-weight: 500;
                                          line-height: normal;
                                    }
                                    
                                    span {
                                          line-height: normal;
                                          font-family: "DM Sans", sans-serif;
                                          font-size: 0.875rem;
                                          font-weight: 500;
                                          text-align: left;
                                    }
                              }
                        }
                  }

                  .recommendedFranchises__gridContainer {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: auto;
                        width: 100%;
                        height: auto;
                        gap: clamp(5px, 0.5vw, 20px);
                        justify-content: space-between;
                        
                        @media (min-width: 600px) and (max-width: 768px) {
                              gap: clamp(10px, 1vw, 20px);
                              grid-template-columns: repeat(3, 1fr);
                        }
                        
                        @media (max-width: 600px) {
                              gap: clamp(5px, 0.5vw, 10px);
                              & > *:nth-child(2n) {
                                    margin-left: auto;
                              }
                        }
                  }
            }

            .recommendedFranchises__lastThree {
                  display: grid;
                  text-wrap: nowrap;
                  flex-wrap: nowrap;
                  grid-template-columns: auto auto auto;
                  margin-top: clamp(10px, 1vw, 20px);
            }
      }
}