.hintContainer {
     position: absolute;
     z-index: 100;
     width: fit-content;
     left: -3rem;
     transform: translateX(-100%);
     border-radius: 1.25rem;
     padding: 1.5rem;
     background: #fff;
     box-shadow: 0 2px 3px 0 rgba(222, 222, 222, 0.29),
                 0 6px 6px 0 rgba(222, 222, 222, 0.26);

     @media (max-width: 1200px) {
          transform: translateY(-75%);
     }

     b {
          font-weight: 600;
          font-size: 1rem;
          line-height: 160%;
          color: #494949;

          &.errored {
               color: #FF6464;
          }
     }
     
     ul {
          margin-left: 1.4rem;
          font-weight: 400;
          font-size: 1rem;
          line-height: 160%;
          color: #8e8e8e;
          list-style-type: "-  ";
     }
     
     .triangle {
          position: absolute;
          top: 50%;
          right: -0.8rem;
          transform: translateY(-50%);
          height: min-content;
          width: min-content;

          svg {
               width: 0.875rem;
               height: auto;
          }
          
          @media (max-width: 1200px) {
               top: 100%;
               right: 50%;
               transform: rotate(90deg) translateX(-50%);
          }
     }

     &.profileHint {
          border: 1px solid rgba(205, 205, 205, 0.4);
          
          .triangle {
               stroke: rgba(205, 205, 205, 0.4);
          }
     }
}