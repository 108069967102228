@import '../../styles/vars.scss';

.multiSelect {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 2px solid $border-color;
    border-radius: 12px;
    background-color: #f9f9f9;
    height: clamp(400px, 35vw, 650px);

    .multiSelect__header {
        padding: 10px 15px 10px 15px;
        border-bottom: 2px solid $border-color;
    }

    .multiSelect__optionsBody {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;
        overflow: auto;

        .optionLabel {
            display: flex;
            position: relative;
            align-items: center;
            padding: 8px 12px;
            width: 100%;
            border: 2px solid $border-color-light;
            border-radius: 6px;
            background-color: #fff;
            transition: background-color ease, border-color ease;
            transition-duration: $transition-duration;
            cursor: pointer;
    
            &:hover {
                background-color: #f1f1f1;
                border-color: #bbb;
            }
    
            &:active {
                background-color: #e0e0e0;
            }
    
            input[type='checkbox'] {
                margin-right: 10px;
                accent-color: #007bff;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
    
            .enumerate {
                position: absolute;
                right: 12px;
                font-weight: 500;
                padding: 2px 8px;
                border: 2px solid $border-color-light;
                border-radius: 6px;
                background-color: #f7f7f7;
            }
        }
    }
}
