
$primary-font-family: "IBM Plex Mono", sans-serif;
$primary-font-family-monospace: "IBM Plex Mono", monospace;
$ui-font-family: "DM Sans", sans-serif;

// Расцветки
$primary-color: #1f1f1f;
$secondary-color:#8d8d8d;

$accent-color: #f6d655;
$accent-background-color: #ffe26d;
$accent-border-color: #ffe26d;
$golden-olive: #7a6c31;

$yellow: #F6D655;

$placeholder-color: #bfbfbf;

$background-white: #ffffff;

$light-gray-color: #aeaeae;
$semi-transparent-gray: #bebebe4d;
$border-gray: #636363;
$background-light-gray: #d9e0ec33;
$background-gray: #f8fafc;
$background-dark-gray: #e7e7e7;

$border-color: #d2d2d2;
$border-color-light: #efefef;

$hover-light-color: #ffe600;
$hover-border-color: #b4b4b4;

$focus-border-color: #a0a0a0;
$focus-border-color-light: #FFE26D;

$icon-color-light: #8e8e8e;

$error-color: #fe5454;
$error-border-color: #FF6464;
$error-placeholder-color: #FF6464;
$error-icon-color: #fe5454;

// Width
$container-width: 1400px;

// Margin
$ul-ol-margin: 0.1rem 1rem 0.1rem 1.8rem;

// Padding
$padding-default: 60px 120px;
$padding-phone: 20px 10px;

@mixin container-padding {
    padding: 60px 120px;

    @media (min-width: 900px) and (max-width: 1199px) {
        padding: 40px 60px; 
    }
  
    @media (min-width: 601px) and (max-width: 899px) {
        padding: 20px 40px; 
    }
    
    @media (max-width: 600px) {
        padding: $padding-phone; 
    }
}

@mixin expend-padding { 
    padding: 60px 160px;

    @media (min-width: 900px) and (max-width: 1199px) {
        padding: 40px 80px; 
    }
  
    @media (min-width: 601px) and (max-width: 899px) {
        padding: 20px 40px; 
    }
    
    @media (max-width: 600px) {
        padding: $padding-phone; 
    }
}

// Animations
@mixin fade-in($duration: $fade-duration) {
    animation: fadeIn $duration ease forwards;
}

@keyframes fadeIn {
    from {
          opacity: 0;
    }
    to {
          opacity: 1;
    }
}

@mixin fade-out($duration: $fade-duration) {
    animation: fadeOut $duration ease forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

// Transitions
$transition-duration: 0.2s;

a,
button,
input,
textarea,
svg * {
    transition-duration: $transition-duration;
}