.spinner {
     display: flex;
     align-items: center;
     position: relative;
     gap: 15px;
     width: 80px;
     height: 80px;
     margin: 150px auto;
}

.spinner div {
     width: 15px;
     height: 15px;
     border-radius: 100%;
     background: #feba4b;
     animation: bounce 1.4s infinite ease-in-out;
}

.spinner .bounce1 {
     animation-delay: -0.32s;
}

.spinner .bounce2 {
     animation-delay: -0.16s;
}

.spinner .bounce3 {
     animation-delay: 0s;
}

@keyframes bounce {
     0%, 20%, 50%, 80%, 100% {
          transform: translateY(0);
     }
     40% {
          transform: translateY(-30px);
     }
     60% {
          transform: translateY(-15px);
     }
}