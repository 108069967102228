@import '../../styles/vars.scss';

.subInput {
    display: flex;
    flex: 0;
    position: relative;
    width: min-content;
    
    @media (max-width: 1250px) {
        width: 100%;
    }

    .inputField {
        font-family: $ui-font-family;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.04em;
        padding: 1.2rem 10.625rem 1.2rem 1.25rem;
        border: 2px solid $border-color-light;
        color: $placeholder-color;
        width: clamp(200px, 23vw, 370px);
        border-radius: 1.875rem;
        outline: none; 
        transition: all $transition-duration ease;
        
        @media (max-width: 1250px) {
            width: 100%;
        }

        &::placeholder {
            color: $placeholder-color;
        }
        
        &:hover {
            border: 2px solid $border-color;
        }

        &:focus {
            border: 2px solid $border-color;
            color: $primary-color;
        }

        &.error {
            border-color: $error-border-color;
            color: $error-color;

            &::placeholder {
                color: $error-placeholder-color;
            }
        }

        &.submitted {
            background-color: rgb(245, 245, 245);
        }
    }

    .SubscribeButton {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.5rem;
    
        & > button {
            padding: 0.9rem 1.8rem;
        } 
    }
}