.imageUpload__container {
      position: relative;
      width: 100%;

      .imageUpload__wrapper {
            margin: 0 auto;
            width: 150px;
            height: 150px;
            border-radius: 100%;
            border: 2px dashed #FFE26D;
            background-color: #f7f9fb;
            transition: all 0.3s ease;
            
            &:hover {
                  border: 2px dashed #ffcc00;
            }

            &.error {
                  border: 2px dashed #fe5454;
            }

            input {
                  display: none;
            }
            
            .image_preview {
                  width: 100%;
                  height: 100%;
                  border-radius: 100%;
                  position: relative;
                  cursor: pointer;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 100%;
                  }

                  span {
                        font-weight: 400;
                        font-size: 0.81rem;
                        line-height: 160%;
                        color: #565656;
                  }
            }
      }
}