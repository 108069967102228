@import '../../styles/vars.scss';

.franchiseApprovalCard {
     display: flex;
     flex-direction: column;
     gap: 0.5rem;
     background-color: white;
     position: relative;
     border-radius: 0.75rem;
     width: 100%;
     padding: 1rem 1.5rem;
     border: 2px solid $border-color-light;
     transition: max-height ease, padding ease;
     transition-duration: $transition-duration;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
                 0 1px 3px rgba(0, 0, 0, 0.08);
                 
     .franchiseInfo__dataItem {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          grid-row: 2 span;
          grid-column: 1 / -1;
          
          span {
               font-size: 0.8rem;
               color: rgba(160, 160, 160, 1);
          }
     
          p {
               line-height: 90%;
               font-size: 1rem;
               font-weight: 500;
          }
     }

     .ownerInfo {
          .franchiseInfo__dataItem {

               &:last-child {
                    align-items: end;
               }
          }
     }

     .franchiseInfo {
          display: flex;
          gap: 0.75rem;
         
          @media (max-width: 768px) {
               flex-direction: column;
               order: 2;
          }
         
          .franchiseInfo__image {
               width: clamp(200px, 180px + 10vw, 400px);
               height: clamp(200px, 180px + 10vw, 400px);
               border-radius: 0.75rem;
               overflow: hidden;

               @media (max-width: 768px) {
                    width: 100%;
               }

               img {
                    width: 100%;
                    height: auto;
                    min-height: 100%;
                    object-fit: cover;
               }
          }
     }
 
     .ownerInfo {
          display: grid;
          grid-template: auto auto / auto auto;
          position: absolute;
          gap: 5px;
          right: 20px;
          
          @media (max-width: 768px) {
               display: flex;
               width: 100%;
               order: 1;
               right: 0;
               position: relative;
               margin-left: auto;
          }
     
          .ownerInfo__name {
               span {
                    font-size: 0.8rem;
                    color: rgba(160, 160, 160, 1);
               }
          }

          .ownerInfo__image {
               width: 3rem;
               height: 3rem;
               border-radius: 100%;
               overflow: hidden;

               @media (max-width: 768px) {
                    margin-right: auto;
               }

               img {
                    width: 100%;
                    height: auto;
                    min-height: 100%;
                    object-fit: cover; 
               }
          }
     }
 
     .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          @media (max-width: 768px) {
               order: 3;
          }
          
          .approveButton, .denyButton {
               border: none;
               cursor: pointer;
               font-size: 0.875rem;
               height: min-content;
               padding: 0.5rem 1rem;
               border-radius: 0.4rem;
          }
          
          .approveButton {
               background-color: transparent;
               border: 2px solid #4a9c4d;
               color: #4a9c4d;
               margin-left: auto;
               transition: all 0.1s ease-in-out;

               &:hover {
                    background-color: #4a9c4d;
                    color: #fff;
               }
          }
          
          .denyButton {
               background-color: transparent;
               border: 2px solid #f63527;
               color: #f63527;
               transition: all 0.1s ease-in-out;

               &:hover {
                    background-color: #f63527;
                    color: #fff;
               }
          }
     }

     .collapsibleContent {
          max-height: 0;
          opacity: 0;
          overflow: hidden;
          transition: max-height ease, opacity ease;
          transition-duration: $transition-duration;
          
          @media (max-width: 768px) {
               order: 4;
          }

          &.expanded {
               max-height: 100%;
               opacity: 1;
          }
     }

     .franchiseInfo__descripton {
          display: flex;
          flex-direction: column;
          gap: 20px;
          border-top: 2px solid $border-color-light;
     }
}