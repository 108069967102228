@import '../../../../styles/vars.scss';

.buyAndSell__container {
    width: 100%;
    height: clamp(500px, 400px + 30vw, 800px);
    @include container-padding;
    
    @media (max-width: 768px) {
        height: auto;
    }
    
    .buyAndSell__content {
        display: flex;
        margin-inline: auto;
        align-items: center;
        max-width: $container-width;
        width: 100%;
        height: 100%;
        gap: clamp(10px, 2.5vw, 40px);
        
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            gap: 0;
        }
        
        .buyAndSell__imagesWrapper {
            position: relative;
            width: clamp(450px, 50vw, 665px);
            height: 100%;
            
            @media (max-width: 768px) {
                display: none;
            }

            .firstImage {
                position: absolute;
                top: 5%;

                img:first-child {
                    position: relative;
                    object-fit: cover;
                    
                    height: clamp(200px, 150px + 8vw, 300px);
                    width: clamp(200px, 150px + 8vw, 300px);

                    border-radius: 0.75rem;
                    top: 0;
                    left: 0;
                }
                
                .owner__image {
                    bottom: -23px;
                    right: -34px;
                }
            }

            .secondImage {
                position: absolute;
                top: 24%;
                left: 55%;
                
                img:first-child {
                    position: relative;
                    object-fit: cover;
                    
                    height: clamp(180px, 120px + 10vw, 270px);
                    width: clamp(180px, 120px + 10vw, 270px);

                    border-radius: 0.75rem;
                }
                
                .owner__image {
                    bottom: -20px;
                    right: -25px;
                }
            }

            .thirdImage {
                position: absolute;
                left: 15%;
                bottom: 15%;

                img:first-child {
                    position: relative;
                    height: clamp(160px, 90px + 8vw, 220px);
                    width: clamp(160px, 90px + 8vw, 220px);
                    object-fit: cover;
                    border-radius: 0.75rem;
                }
                
                .owner__image {
                    bottom: -35px;
                    right: -40px;
                }
            }
        }

        .buyAndSell__text {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: clamp(15px, 1.8vw, 30px);
            
            h1 {
                font-size: 2.2rem;
                font-weight: 600;
                line-height: 130%;
            }
            
            p {
                font-family: $ui-font-family;
                font-size: 1.125rem;
                line-height: 160%;
                color: $secondary-color;
                text-align: left;
            }
            
            button {
                @media (max-width: 768px) {
                    float: right;
                }
            }
        }
    }
}