@import '../../styles/vars.scss';

.franchise__container {
    display: flex;
    flex-direction: column;
    @include expend-padding;
    width: 100%;

    .franchise__wrapper {
        max-width: $container-width;
        margin-inline: auto;
        width: 100%;

        .franchise__characteristic {
            display: grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: auto auto 1fr;
            width: 100%;
            row-gap: 0.2rem;
            column-gap: 1rem;
    
            @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .franchise__image {
                grid-row: 3 span;
                display: flex;
                width: clamp(350px, 320px + 8vw, 500px) !important;
                height: clamp(400px, 370px + 8vw, 550px) !important;
                min-height: 100% !important;
                aspect-ratio: 16 / 9;
                border-radius: 1.5rem !important;
                object-fit: cover;
                flex-shrink: 0;

                @media (max-width: 768px) {
                    min-width: 100% !important;
                    height: clamp(250px, 200px + 20vw, 375px) !important;
                    object-fit: cover;
                    order: 2;
                }
            }

            h1 {
                font-size: 2.25rem;
                letter-spacing: -0.05em;
                line-height: 120%;

                @media (max-width: 768px) {
                    order: 1;
                }
            }

            .franchise__infoTable {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-height: 100%;
                height: auto;

                @media (max-width: 768px) {
                    order: 3;
                }
                
                .infoTable__row {
                    display: grid;
                    position: relative;
                    grid-template-columns: 50% auto;
                    padding: 6px 0 6px 0;
                
                    &:not(:last-child)::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 1px;
                        background-color: $border-color-light;
                    }
                }
            }

            .franchise__button {
                display: flex;

                @media (max-width: 768px) {
                    order: 4;
                    
                    button {
                        flex-basis: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .franchise__descriptionBlocks {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 2rem;
            gap: 2rem;

            h1 {
                font-size: 2.25rem;
                letter-spacing: -0.05em;
            }
            
            .franchisor_description {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                margin: 0;
                padding: 0;

                .description__text {
                    margin: 0;
                    padding: 0;
                    padding: 20px;
                    border: 2px solid #f3f3f3;
                    background: #fafafa;
                    font-weight: 500;
                    border-radius: 0.75rem;
                    width: 100%;

                    h3, h4 {
                        margin: 0;
                        padding: 0;
                    }

                    ul, ol {
                        margin: $ul-ol-margin;
                    }

                    p, li {
                        color: #4d4d4d;
                    }
                    
                    strong {
                        color: $primary-color;
                    }
                }
            }

            .franchise__gallery {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }
    }
}