@import '../../../styles/vars.scss';

.profile__card {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 1.5rem;
      gap: 1.8rem;
      padding: 1.6rem 2rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      background: #fff;

      h2 {
            font-weight: 600;
            font-size: 1.5rem;
            letter-spacing: 0.05em;
            color: $primary-color;
            text-align: center;
      }
      
      .communicate__button {
            align-self: flex-end;
      }

      .leave__button {
            position: absolute;
            cursor: pointer;
            top: 6em;
            right: 2rem;
            
            svg {
                  width: 2.4rem;
                  height: 2.4rem;
            }
      }

      .profile__box {
            display: flex;
            align-items: center;
            gap: 1rem;
            
            h3 {
                  font-size: 1.25rem;
                  letter-spacing: 0.05em;
            }

            .profileImage {
                  width: clamp(100px, 80px + 10vw, 180px);
                  height: clamp(100px, 80px + 10vw, 180px);
                  aspect-ratio: 16 / 9;
                  border-radius: 100%;
                  overflow: hidden;

                  img {
                        min-width: 100%;
                        height: auto;
                        min-height: 100%;
                        object-fit: cover;
                  }
            }
      }
      
      .card__info {
            width: 100%;

            h2 {
                  font-size: 1.8rem;
            }

            p {
                  color: $secondary-color;
                  font-size: 1rem;
                  font-weight: 400;
                  line-height: 155%;
            }
      }

      .userCard_gridBox {
            display: grid;
            grid-template-columns: auto auto;
            gap: 1.2rem;
            width: 100%;
            
            @media (max-width: 500px) {
                  display: flex;
                  flex-direction: column;
            }
      }

      span {
            font-weight: 400;
            font-size: 1.1rem;
            letter-spacing: 0.05em;
            color: $secondary-color;
      }
}