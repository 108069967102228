@import '../../../styles/vars.scss';

.newsItem__container {
     display: flex;
     gap: clamp(10px, 1vw, 20px);
     align-items: center;
     width: 100%;

     &.even {
          .news__image {
               order: 1;
          }
          .content__box {
               order: 2;
          }
     }

     &.odd {
          .news__image {
               order: 2;
               justify-content: flex-end;
          }
          .content__box {
               order: 1;
          }
     }

     .news__image {
          width: clamp(200px, 15vw, 270px) !important;
          height: clamp(200px, 15vw, 270px) !important;
          border-radius: 24px !important;
          overflow: hidden;
          object-fit: cover;

          @media (max-width: 600px) {
               display: none;
          }
     }

     .content__box {
          display: flex;
          position: relative;
          flex: 1;
          flex-direction: column;
          gap: 5px;

          background: white;
          padding: 1.6rem 4.2rem 1.6rem 2.2rem;
          border-radius: 1.8rem;
          
          h2 {
               display: -webkit-box;
               -webkit-line-clamp: 2;
               text-overflow: ellipsis;
               -webkit-box-orient: vertical;
               white-space: normal;
               overflow: hidden;
               font-size: 1.5rem;
               font-weight: 600;
               line-height: 120%;
          }

          .news__creationTime {
               display: flex;
               align-items: center;
               margin-top: 5px;
               gap: 5px;
               font-size: 1rem;
               font-weight: 400;
               line-height: 150%;
               color: $secondary-color;
          }

          .news__description {
               display: -webkit-box;
               -webkit-line-clamp: 3;
               text-overflow: ellipsis;
               -webkit-box-orient: vertical;
               white-space: normal;
               overflow: hidden;
               max-width: 90%;
               line-height: 110%;
          }

          .read__button {
               position: absolute;
               right: -0.6rem;
               bottom: -0.6rem;
               border: 0.6rem solid rgba(247, 249, 251, 1);
               border-radius: 60px; 
          }
     }
}