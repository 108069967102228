@import '../../styles/vars.scss';

.overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
}
  
.overlay--hidden {
      display: none;
}

.franchiseCreator__container {
      width: 100%;
      @include container-padding;
        
      .franchiseCreator__content {
            display: flex;
            max-width: $container-width;
            margin-inline: auto;
            width: 100%;
            flex-direction: column;
            gap: clamp(15px, 1.5vw, 30px);

            .franchiseCreator__header {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  
                  h1 {
                        font-size: 2.5rem;
                        letter-spacing: 0.05em;
                        line-height: 130%;
                  }
                
            }

            h2 {
                  font-size: 2rem;
                  letter-spacing: 0.05em;
            }

            .franchiseCreator__body {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr;
                  gap: clamp(10px, 1vw, 20px);

                  @media (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                  }

                  .franchise__faceImage {
                        display: flex;
                        width: 100%;
                        justify-content: center;

                        & > :first-child {
                              flex-basis: 100%;
                              height: 100%;
                        }
                  }

                  .franchise__column {
                        display: flex;
                        flex-direction: column;
                        gap: clamp(20px, 2vw, 40px);
                  }
            }

            .franchise__controllers {
                  display: flex;
                  justify-content: space-between;
            }
      }
}