@import '../../styles/vars.scss';

.questionnaireInput__container {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 1.5rem;
      width: 100%;
      padding: 1.375rem 1.875rem 2.25rem;
      background: rgba(217, 224, 236, 0.25);
      
      .questionnaireInput__header {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 160%;
            color: #565656;
            
            .requiredMarker {
                  color: $accent-color;
            }

            .errorMarker {
                  color: $error-color;
            }
      }

      .inputWrapper {
            position: relative;
            margin-top: 2.5rem;
            transition: all $transition-duration ease;
    
            input {
                  background-color: transparent;
                  border: none;
                  outline: none;

                  font-weight: 500;
                  font-size: 1rem;
                  letter-spacing: 0.04em;

                  &::placeholder {
                        color: #aeaeae;
                  }
            }
            
            &::before {
                  content: "";
                  position: absolute;
                  width: clamp(300px, 200px + 5vw, 350px);
                  height: 1.5px;
                  background-color: #c0c0c0;
                  bottom: -9px;
                  left: 0;
                  transition: background-color $transition-duration ease;

                  @media (max-width: 768px) {
                        min-width: 100%;
                  }
            }
      
            &:hover::before {
                  background-color: #828282;
            }
      }

      .phoneInputWrapper {
            position: relative;
            margin-top: 2.5rem;
            transition: all $transition-duration ease;
            
            &::before {
                  content: "";
                  position: absolute;
                  width: 350px;
                  height: 1.5px;
                  background-color: #c0c0c0;
                  bottom: -9px;
                  left: 0;
                  transition: background-color $transition-duration ease;
                  
                  @media (max-width: 500px) {
                        width: 100%;
                  }
            }

            .phoneInput {
                  border: none;
                  outline: none;
                  font-weight: 500;
                  font-size: 1rem;
                  letter-spacing: 0.04em;
                  width: clamp(300px, 200px + 5vw, 350px);
                  background-color: transparent;

                  @media (max-width: 500px) {
                        width: 100%;
                  }
            }

            .phoneInputButton {
                  border-radius: 4px;
                  border: 1px solid $border-color;
            }
      }

      .yesNoWrapper {
            display: flex;
            flex-direction: column;
            margin-top: 1rem;
            gap: 0.6rem;

            label {
                  display: flex;
                  align-items: center;
                  gap: 1rem;

                  border-radius: 0.75rem;
                  width: 50%;

                  font-weight: 400;
                  font-size: 1.3rem;
                  line-height: 160%;
                  color: #565656;
                  cursor: pointer;

                  padding: 0.4rem;
                  transition: all $transition-duration ease;
                    
                  @media (max-width: 500px) {
                        width: 100%;
                  }

                  &:hover {
                        background-color: rgba(0, 0, 0, 0.05)
                  }

                  input {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        width: 2rem;
                        height: 2rem;
                        border: 0.1rem solid $accent-color;
                        border-radius: 100%;
                        position: relative;
                        cursor: pointer;
                        outline: none;
                        background-color: white;
                        transition: all $transition-duration ease;
        
                        &:checked::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -70%) rotate(-45deg);
                            width: 1rem;
                            height: 0.5rem;
                            border-left: 2px solid white;
                            border-bottom: 2px solid white;
                        }
        
                        &:checked {
                            background-color: $accent-color;
                            border-color: $accent-color;
                        }
                  }
            }
      }

      .errorMessage {
            position: absolute;
            color: $error-color;
            font-size: 0.8rem;
            bottom: 0.25rem;
      }
}