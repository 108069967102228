.skeleton {
     animation: shine 1.5s infinite;
}

.skeletonLight {
     background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
     background-size: 200% 100%;
}

.skeletonDark {
     background: linear-gradient(90deg, #e0e0e0 25%, #c0c0c0 50%, #e0e0e0 75%);
     background-size: 200% 100%;
}

@keyframes shine {
     from {
          background-position: 200% 0;
     }
     to {
          background-position: -200% 0;
     }
}
