@import '../../styles/vars.scss';

.interactiveCards__wrapper {
    position: relative;
    width: clamp(260px, 220px + 16vw, 400px);
    height: clamp(300px, 260px + 12vw, 440px);
    
    .franchise_card {
        position: absolute;
        transition: all 0.2s ease;
        cursor: pointer;

        &:nth-child(2) {
            left: 12%;
            transform: scale(0.9);
            z-index: 2;
        }

        &:nth-child(3) {
            left: 24%;
            transform: scale(0.8);
            z-index: 1;
        }

        &:not(:first-child):hover {
            margin-left: 5%;
        }

        &.active {
            transform: scale(1);
            z-index: 3;
        }
    }
}