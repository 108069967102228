@import '../../styles/vars.scss';

.profile {
      width: 100%;
      @include container-padding;

      .profile__content {
            max-width: $container-width;
            margin-inline: auto;
            width: 100%;
            
            .profile__wrapper {
                  display: grid;
                  grid-template-columns: 3fr 1fr;
                  gap: 45px;
                  width: 100%;
                  height: fit-content;
                  
                  @media (max-width: 999px) {
                        display: block;
                  }

                  .userData__box {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 20px;
                        
                        h1 {
                              font-size: 2.5rem;
                              line-height: 100%;
                              letter-spacing: 0.05em;
                        }
                  }

                  .advertising_banner {
                        max-width: 100%;
                        max-height: 100%;

                        @media (max-width: 999px) {
                              display: none;
                        }

                        img {
                              object-fit: contain;
                              width: 100%;
                              height: 100%;
                        }
                  }
            }
      }
}
