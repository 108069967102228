@import '../../styles/vars.scss';

.franhises__galery {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px 0;

    .franhises__galerySwiper {
        width: 100%;
        position: relative;
        overflow: visible;
        
        :global(.swiper-pagination) {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: -20px; 
        }

        :global(.swiper-pagination-bullet) {
            width: 0.8rem;
            height: 0.8rem;
            background-color: $semi-transparent-gray; 
            opacity: 1;
            transition: background-color $transition-duration ease;
        }

        :global(.swiper-pagination-bullet-active) {
            background-color: $yellow;
        }
    }

    .galerySwiper__item {
        display: flex;
        justify-content: center;
    }
}
