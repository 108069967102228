@import '../../../styles/vars.scss';

.userFranchise {
     width: 100%;
     margin-top: 2.5rem;
     gap: 2.5rem;
     
     .userFranchise__content {
          display: flex;
          flex-direction: column;
          max-width: $container-width;
          margin-inline: auto;
          gap: 2rem;

          .userFranchise__header {
               display: flex;
               align-items: center;
               justify-content: space-between;
          }

          .userFranchise__box {
               display: flex;
               flex-direction: column;
               gap: 2rem;
          }
     }
}