.filterButton {
      display: inline;
      font-size: 0.875rem;
      font-weight: 500;
      color: #7A6C31;
      padding: 0.6rem 1.2rem;
      background: rgba(255, 226, 109, 1);
      border-radius: 3.75rem;
      outline: none;
      border: none;
      cursor: pointer;
}

.inactive {
      color: #AEAEAE;
      background-color: rgba(220, 220, 220, 0.45);
      transition: all 0.1s ease;

      &:hover {
            background-color: rgba(97, 97, 97, 0.2);
            color: #6d6d6d;
      }
}