@import '../../styles/vars.scss';

.catalog__container {
     width: 100%;
     @include container-padding;
     background-color: $background-gray;

     .catalog__content {
          display: flex;
          flex-direction: column;
          margin-inline: auto;
          max-width: $container-width;
          gap: clamp(10px, 1vw, 20px);
          
          .catalog__header {
               display: grid;
               grid-template-columns: auto;
               grid-template-rows: auto auto;
               grid-gap: clamp(8px, 0.8vw, 16px);
               align-items: center;
          
               @media (max-width: 768px) {
                    grid-template-columns: 100%;
                    grid-template-rows: auto auto auto;
               }
          
               h1 {
                    font-size: 2.2rem;
                    grid-column: 1 / -1; 
                    grid-row: 1;

                    @media (max-width: 768px) {
                         grid-column: 1; 
                    }
               }
          
               .allFilters__button {
                    grid-row: 2;
                    grid-column: 2;
                    align-self: start;
                    
                    @media (max-width: 768px) {
                         grid-row: 1;
                         grid-column: 1 / -1;
                         align-self: unset; 
                         justify-self: end;
                    }
               }
          
               .industrieFilters__wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    gap: clamp(8px, 0.8vw, 16px);
                    grid-row: 2;
                    grid-column: 1 / -1;
          
                    @media (max-width: 768px) {
                         grid-row: 2; 
                         grid-column: 1 / -1;
                         margin-top: clamp(8px, 0.8vw, 16px);
                    }
               }
          }
          
          $cell-width: clamp(160px, 140px + 10vw, 260px);
          .catalog__franchisesWrapper {
               display: grid;
               width: 100%;
               gap: clamp(10px, 1vw, 20px);
               justify-content: space-between;
               grid-template-columns: repeat(auto-fill, $cell-width); 
               grid-template-rows: auto;
               height: auto;

               @media (min-width: 1600px) {
                    grid-template-columns: repeat(5, $cell-width);
               }

               @media (max-width: 600px) {
                    grid-template-columns: repeat(2, auto);
                    gap: clamp(5px, 0.5vw, 10px);
               }
          }
     }
}
