@import '../../styles/vars.scss';

.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    
    input {
        position: relative;
        width: 100%;
        padding: 0.8125rem 3.125rem 0.8125rem 1.25rem;
        outline: none;
        border: 2px solid $border-color;
        border-radius: 6.25rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.04em;
        transition: border 0.3s ease;

        &:hover {
            border-color: $hover-border-color
        }

        &:focus {
            border-color: $focus-border-color;
        }

        &::placeholder {
            color: $placeholder-color;
        }

        &.ErrorInput {
            border: 2px solid $error-border-color;
            color: $error-color;
        
            &::placeholder {
                color: $error-placeholder-color;
            }
        }
    }

    .inputIcon {
        position: absolute;
        right: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .inputIcon.ErrorIcon * {
        stroke: $error-icon-color;
    }
}