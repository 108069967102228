@import '../../styles/vars.scss';

.franchiseCard__container {
      display: flex;
      width: 100%;
      height: fit-content;
      gap: clamp(5px, 0.5vw, 10px);
      
      @media (max-width: 768px) {
            flex-direction: column;
            max-width: 200px;
            background-color: $background-light-gray;
            border-radius: 20px;
            padding: 0.6rem;
      }

      .franchiseCard__image {
            width: clamp(100px, 80px + 4vw, 140px) !important;
            height: clamp(100px, 80px + 4vw, 140px) !important;
            border-radius: 12px !important;
            object-fit: cover;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;

            @media (max-width: 768px) {
                  display: flex;
                  flex: 1;
                  min-width: 100%;
                  min-height: 100%;
                  height: clamp(140px, 100px + 10vw, 220px) !important;
            }
      }


      .franchiseCard__infoWrapper {
            display: flex;
            height: 100%;
            flex: 1;
            flex-direction: column;
            margin-top: 10px;
            
            @media (max-width: 768px) {
                  margin-top: 0;
            }

            span {
                  display: -webkit-box;
                  -webkit-line-clamp: 1; 
                  padding-bottom: 2px;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis; 
                  white-space: normal;
                  font-size: 1.25rem;
                  font-weight: 500;
                  line-height: 120%;
            }

            .infoBox {
                  display: flex;
                  flex-wrap: wrap;
                  padding: 5px 0;
                  align-items: center;
                  width: 100%;

                  .infoBox__ownerImage {
                        width: 2rem !important;
                        height: 2rem !important;
                        border-radius: 100% !important;
                        cursor: pointer;
                        margin-right: 8px;
                        overflow: hidden;
                 
                        img {
                              width: 100%;
                              height: auto;
                              min-height: 100%;
                              object-fit: cover;
                        }
                  }

                  .infoBox__price {
                        display: inline-block;
                        font-size: 0.75rem;
                        font-weight: 700;
                        padding: 0.3rem;
                        color: $golden-olive;
                        border: 1.5px solid $accent-border-color;
                        border-radius: 4px;

                       

                        @media (max-width: 768px) {
                              border-width: 1.5px;
                              border-radius: 20px;
                        }
                  }

                  .infoBox__paybackTime {
                        display: flex;
                        align-items: center;
                        margin-left: 0.2rem;
                        gap: 0.1rem;
                        padding: 5px 0;
                        
                        @media (min-width: 768px) and (max-width: 1199px) {
                              flex-basis: 100%;
                        }

                        svg {
                              width: 0.7rem;
                              margin-bottom: 0.15rem;
                        }

                        span {
                              line-height: 80%;
                              text-align: end;
                              font-size: 0.75rem;
                              font-weight: 500;
                              color: $secondary-color;
                        }
                  }
            }
      }
}