.comeBack {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.04em;
    cursor: pointer;

    .comeBack__arrow {
        width: 1.8rem;
    }
}