@import '../../styles/vars.scss';

.searchItem__wrapper {
    width: 100%;
    padding: 0.4rem;
    transition: all $transition-duration ease;
    
    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }

    .searchItem__body {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 0.4rem;

        p {
            width: 100%;
            font-weight: 500;
            font-size: 1rem;
            line-height: 120%;
            color: $primary-color;
        }

        .franchiseImage {
            height: clamp(40px, 20px + 8vw, 60px);
            width: clamp(40px, 20px + 8vw, 60px);
            border-radius: 4px;
            object-position: center;
            object-fit: cover;
            aspect-ratio: 16 / 9;
        }

        .searchIcon {
            margin-left: auto;
            margin-right: 0.6rem;
        }
    }

    &:hover {
        background-color: #efefef;
    }
}