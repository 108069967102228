@import "../../../../styles/vars.scss";

.findYourFranchise__container {
    width: 100%;
    background: $background-gray;
    @include container-padding;

    .findYourFranchise__content {
        display: flex;
        margin-inline: auto;
        max-width: $container-width;

        @media (max-width: 768px) {
            padding: 40px 0;
            flex-direction: column;
        }

        h1 {
            font-size: 3.4rem;
            letter-spacing: 0.05em;
            color: $primary-color;
            margin-left: 20px;

            @media (max-width: 768px) {
                font-size: 2.5rem;
                letter-spacing: 0;
                margin: 0;
            }

            span {
                color: $accent-color;
            }
        }

        .questionaryBox {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            width: 50%;
            align-items: end;

            
            @media (max-width: 768px) {
                width: 100%;
                margin: 20px 0;
            }

            h3 {
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 160%;
                color: $secondary-color;
                margin-bottom: auto;
            }
        }
    }
}