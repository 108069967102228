@import '../../styles/vars.scss';

.draftEditor {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    min-height: 150px;
    
    .toolbar {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
        row-gap: 0.5rem;
    
        button {
            background-color: transparent;
            border: 2px solid $border-color-light;
            border-radius: 0.25rem;
            padding: 0.4rem;
            margin-right: 0.5rem;
            cursor: pointer;
            transition: all $transition-duration ease;
            font-size: 0.9rem;
              
            @media (max-width: 768px) {
                transition: none;
            }
            
            &:hover {
                background-color: $background-light-gray;
                border-color: $border-color;
            }
            
            &:focus {
                outline: none;
                border-color: $accent-border-color;
            }
            
            &.active {
                border-color: $accent-border-color;
                font-weight: bold;
            }
          
        }
    }
    

    .editor {
        border: 2px solid #FFE26D;
        border-radius: 12px;
        padding: 10px;
        position: relative;
        width: 100%;
        height: auto;
        min-height: 150px;
        overflow: hidden;
        cursor: text;
    }

    ul, ol {
        margin: $ul-ol-margin;
    }

    .charCount {
        position: absolute;
        right: 10px;
        bottom: 2px;
        font-size: 0.75rem;
        font-weight: 500;
        color: #565656;
    }
    
    .errorIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        background-color: #fff;
        transform: translateY(-50%);
        color: red;
        display: none;
    }

    .errorMessage {
        display: none;
    }

    &.error {
        .editor {
            border-color: $error-border-color;
        }

        .DraftEditor-root {
            &::placeholder {
                color: #ff6464;
            }
        }

        .errorIcon {
            display: block;
        }

        .errorMessage {
            position: absolute;
            display: block;
            color: red;
            font-size: 0.75rem;
            bottom: -19px;
        }
    }
}