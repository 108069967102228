@import '../../styles/vars.scss';

.customButton {
    width: fit-content;
    height: fit-content;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 100%;
    color: $golden-olive;
    padding: 1.1rem 1.8rem;
    letter-spacing: 0.04em;
    background: $accent-background-color;
    border-radius: 3.75rem;
    outline: none;
    border: 2px solid $accent-background-color;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
        padding: 1rem 1.5rem;
    }

    @media (max-width: 500px) {
        padding: 0.8rem 1.3rem;
    }

    &:hover {
        filter: brightness(105%);
    }
    
    &.empty {
        background-color: #fff;
    }

    .spinner {
        border: 3px solid rgba(0, 0, 0, 0.2);
        border-top: 3px solid #fff;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        animation: spin 0.6s linear infinite;
        display: inline-block;
    }

    .circle {
        position: absolute;
        border-radius: 100%;
        background-color: #fff;
        width: clamp(1.5rem, 2vw, 1.7rem);
        height: clamp(1.5rem, 2vw, 1.7rem);
        
        &:nth-child(1) {
            left: -2%;
            bottom: 0;
            transform: translateY(35%);
        }

        &:nth-child(2) {
            right: -2%;
            top: 0;
            transform: translateY(-25%);
        }

        &:nth-child(3) {
            left: 48.5%;
            top: 0;
            transform: translateY(-65%);
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}