@import '../../styles/vars.scss';

.news__container {
    width: 100%;
    @include container-padding;
    background: $background-gray;
    
    .news__content {
        margin-inline: auto;
        max-width: $container-width;
        
        .news__card {
            padding: 1.5rem;
            background-color: white;
            margin-top: 0.5rem;
            border-radius: 1.5rem;
            box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
            
            .card__info {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-template-rows: auto auto auto auto;
                gap: 1rem;
                margin-bottom: 1rem;
                padding-bottom: 1rem;
                border-bottom: 2px solid $border-color-light;

                @media (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }

                @media (max-width: 1000px) {
                    grid-template-rows: auto auto auto;
                }
                
                .news__image {
                    grid-row: 4 span;
                    width: clamp(200px, 15vw, 270px) !important;
                    height: clamp(200px, 15vw, 270px) !important;
                    border-radius: 1.5rem !important;
                    object-fit: cover;
                    aspect-ratio: 16 / 9;
                    overflow: hidden;

                    @media (max-width: 1000px) {
                        grid-row: 3 span;
                        grid-template-rows: auto auto auto;
                    }

                    @media (max-width: 768px) {
                        min-width: 100%;
                        height: clamp(200px, 150px + 25vw, 300px) !important;
                        order: 3;
                    }
                }

                h1 {
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 120%;
                    
                    @media (max-width: 768px) {
                        order: 1;
                    }
                }

                .news__creationTime {
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: normal;
                    color: $secondary-color;

                    @media (max-width: 768px) {
                        order: 2;
                    }
                }
                
                p {
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 120%;
                    letter-spacing: 0.04em;
                    color: $primary-color;

                    @media (max-width: 1000px) {
                        grid-column: 1 / -1;
                        grid-template-rows: auto auto auto;
                    }

                    @media (max-width: 768px) {
                        order: 4;
                    }
                }
            }

            .news__additionalInfo {
                position: relative;
                border-radius: 0.75rem;
                padding: 1rem;
                color: $primary-color;
                border: 2px solid $border-color-light;
                background-color: rgb(255, 255, 255);

                font-weight: 500;
                font-size: 1rem;
                line-height: 130%;
                letter-spacing: 0.04em;

                h3, h4 {
                    margin: 0;
                    padding: 0;
                }
                
                ul, ol {
                    margin: $ul-ol-margin;
                }
                
                p, li {
                    color: #4d4d4d;
                }
                
                strong {
                    color: $primary-color;
                }
            }
        }
    }
}