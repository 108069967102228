@import '../../styles/vars.scss';

.adminPage__container {
      position: relative;
      width: 100%;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.2);
      @include container-padding;

      &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url('../../assets/animation.gif') right / cover no-repeat;
          opacity: 1;
          z-index: -2;

        @media (max-width: 500px) {
            background-position: center;
        }
    }
      
      &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: repeating-linear-gradient(
              -45deg,
              rgba(217, 224, 236, 0.4),
              rgba(217, 224, 236, 0.4) 10px,
              transparent 10px,
              transparent 20px
          );
          z-index: -1;
      }
      
      .adminPage__content {
            display: flex;
            max-width: $container-width;
            margin-inline: auto;
            flex-direction: column;
            gap: 2rem;

            @media (max-width: 500px) {
                align-items: center;
                justify-content: center;

                .admin__tool {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
        
                    button {
                        font-size: 1.2rem;
                        width: 100%;
                    }
                }
            }

            button {
                background-color: rgba(255, 255, 255, 0.5);
                color: white;
                border-color: #ffffff;
                font-weight: 500;
            }

            h1 {
                color: #ffffff;
                font-size: 2rem;
            }
      }
}