@import '../../styles/vars.scss';

footer {
    width: 100%;
    position: relative;
    @include container-padding;

    &::before {
        position: absolute;
        width: 95%;
        content: '';
        height: 2px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $border-color-light;
    
        @media (max-width: 768px) {
            height: 1px;
        }
    }
    
    .footer__container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        position: relative;

        .footer__content {
            display: flex;
            flex-direction: column;
            margin-inline: auto;
            width: 100%;
            max-width: $container-width;
            
            .footer__body {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 20px;

                @media (max-width: 768px) {
                    align-items: end;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 40px;
                    gap: 40px;
                }

                .footer__column {
                    display: flex;
                    flex-direction: column;
                    gap: clamp(10px, 3vw, 25px);
                    width: 100%;

                    h3 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        line-height: 100%;
                    }

                    p {
                        color: $secondary-color;
                        font-size: 0.875rem;
                        font-weight: 500;
                        line-height: 180%;
                        text-align: left;
                    }
                    
                    .socialMedia {
                        display: flex;
                        gap: 16px;

                        img {
                            width: 2rem;
                            height: 2rem;
                        }
                    }

                    .footer__navigation {
                        width: 100%;
                        display: grid;
                        grid-template-columns: auto auto auto;
                        list-style: none;

                        .buttons__box {
                            display: flex;
                            flex-direction: column;
                            font-size: 0.875rem;
                            font-weight: 500;
                            line-height: 28px;
                            text-align: left;
                            
                            a {
                                color: $secondary-color;
                                transition: color 0.3s ease;

                                &:hover {
                                    color: $primary-color;
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer__copyright {
            text-align: center;
            font-family: $primary-font-family-monospace;
            color: $placeholder-color;
            font-size: 0.875rem;
            font-weight: 400;
            margin-top: 2rem;
            line-height: 100%;
        }
    }
}