@import "../../../../styles/vars.scss";

.welcome__container {
    width: 100%;
    @include container-padding;
    
    .welcome__content {
        display: flex;
        justify-content: space-between;
        margin-inline: auto;
        max-width: $container-width;
        
        @media (max-width: 768px) {
            flex-direction: column;
        }
        
        .welcome__box {
            height: fit-content;
            margin: 40px 20px;
            width: 100%;
            z-index: 2;
            
            @media (max-width: 768px) {
                margin: 20px 0;
            }

            .welcome__text {
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 20px;

                h1 {
                    font-size: 2.5rem;
                    line-height: 130%;
                    letter-spacing: 0.05em;
                }

                p {
                    color: $secondary-color;
                    font-size: 1.25rem;
                    font-weight: 400;
                    line-height: 160%;
                    text-align: left;
                }
            }
            
            .welcome__buttonWrapper {
                position: relative;
                width: min-content;
                text-wrap: nowrap;
                height: 100%;

                @media (max-width: 768px) {
                    float: right;
                }

                .welcome__button {
                    font-size: 1rem;
                    z-index: 1;
                }
    
                .dots__image {
                    position: absolute;
                    z-index: -1;
                    top: -25%;
                    left: -10%;
                    width: clamp(100px, 80px + 8vw, 220px);
                    height: auto; 
    
                    @media (max-width: 768px) {
                        left: -22%;
                        top: -30%;
                    }
                }
            }
        }
    }
}