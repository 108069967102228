.fileUploader {
      display: flex;
      position: relative;
      width: clamp(260px, 200px + 12vw, 400px);
      height: clamp(260px, 220px + 15vw, 440px);

      label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-weight: 600;
            border-radius: 24px;
            object-fit: cover;
            background-color: #f7f9fbb0;
            transition: all 0.3s ease;
            aspect-ratio: 16 / 9;
            border: 2px dashed #FFE26D;
            overflow: hidden;
            cursor: pointer;

            &.disabled {
                  border-color: transparent;
                  cursor: default;

                  &:hover {
                        border-color: transparent;
                  }
            }
            
            &:hover {
                  border-color: #ffcc00;
            }
            
            &.error {
                  border-color: #fe5454;
            }
            
            .imagePreview {
                  width: 100%;
                  height: 100%;

                  img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                  }
            }
      }

      input {
            display: none;
      }

      .errorMessage {
            position: absolute;
            width: 600px;
            left: 0;
            color: red;
            height: 20px;
            font-size: 0.75rem;
            bottom: -30px;
      }
}