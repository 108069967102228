@import '../../styles/vars.scss';

.modalOverlay {
      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 100%;
      min-height: 100%;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      background: rgba(0, 0, 0, 0.9);
      z-index: 1000;
      animation: $transition-duration ease forwards;
      cursor: pointer;

      .modalContent {
            display: flex;
            position: relative;

            width: auto;
            height: auto;

            .modalImage__body {
                  display: flex;
                  cursor: default;

                  img {
                        width: auto;
                        height: auto;
                        max-width: 90vw;
                        max-height: 90vw;
                        border-radius: 0;
                        object-fit: contain;
                        cursor: default;
                  }
                  .closeButton {
                        position: absolute;
                        top: -5px;
                        right: 10px;
                        background: none;
                        border: none;
                        color: white;
                        font-size: 2rem;
                        transition: all $transition-duration ease;
                        cursor: pointer;
      
                        &:hover {
                              color: rgb(255, 230, 0);
                        }
                  }
            }
            
      }
}