@import '../../styles//vars.scss';

.multipleFileUploader {
      display: flex;
      align-items: center;
      position: relative;
      padding: 3rem 1rem;
      width: 100%;
      height: clamp(180px, 150px + 10vw, 260px);
      border-radius: 1.5rem;
      background-color: $background-gray;
      transition: all $transition-duration ease;
      border: 2px dashed $accent-color;

      .errorMessage {
            position: absolute;
            left: 0;
            bottom: -1rem;
            color: $error-color;
            font-size: 0.75rem;
            bottom: -1.3rem;
      }

      &.error {
            border-color: $error-border-color;

            &:hover {
                  border-color: rgb(255, 140, 0);
            }
      }

      &.dragging {
            border-color: #ffb300;
      }

      &:hover {
            border-color: #ffcc00;
      }

      .dropZone {
            width: 100%;
            font-weight: 500;
            font-size: 1rem;
            line-height: 150%;
            text-align: center;
            color: #1f1f1f;

            span {
                  color: #8E8E8E;
            }
      }

      label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
      }
      
      .labelImage {
            width: 2rem;
            height: 2rem;
      }

      input {
            display: none;
      }

      .swiper__uploader {
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            
            .swiper_slide {
                  height: clamp(120px, 100px + 10vw, 200px);
                  width: clamp(180px, 150px + 10vw, 260px);
      
                  .swiperImage_container {
                        display: flex;
                        width: clamp(180px, 150px + 10vw, 260px);
                        height: 100%;
                        object-fit: cover;
                        border-radius: 12px;
                        overflow: hidden;

            
                        img {
                              object-fit: cover;
                              width: 100%;
                              height: 100%;
                        }
                        
                        &:hover {
                              cursor: pointer;
                        
                              &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0, 0, 0, 0.7);
                                    border-radius: 12px;
                              }
                  
                              &::after {
                                    content: url('../../assets/icons/garbage.svg');
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                              }
                        }
                  }

                  label {
                        display: flex;
                        justify-content: flex-start;
                        border-radius: 12px;
                        width: clamp(180px, 150px + 10vw, 260px);
                        padding-left: 20px;
                  }
            }
      }
}