@import '../../styles/vars.scss';

.franchise__card {
    display: flex;
    position: relative;
    color: #ffffff;
    width: clamp(260px, 200px + 12vw, 400px) !important;
    height: clamp(260px, 220px + 15vw, 440px) !important;
    
    @media (min-width: 501px) and (max-width: 768px) {
        min-width: 450px;
    }
    
    @media (max-width: 500px) {
        width: 100% !important;
        flex: 1;
    }
    
    .franchise__faceImage {
        position: relative;
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        border-radius: 24px;
        overflow: hidden;
    }

    .franchise__userProfile {
        position: absolute;
        padding: 5%;
        width: 100%;

        h1 {
            font-size: 1.75rem;
            line-height: 130%;
            letter-spacing: -0.05em;
        }

        .franchise__author {
            display: flex;
            align-items: center;
            margin-top: 9px;
            gap: 12px;
            
            .franchise__authorImage {
                width: 2rem;
                height: 2rem;
                border-radius: 100%;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    min-height: 100%;
                    object-fit: cover;
                }
            }

            h2 {
                font-size: 1.25rem;
            }
        }
    }
    
    .editorsChoice {
        position: absolute;
        bottom: 26%;
        transform: translateX(-50%) rotate(-90deg);
        
        width: clamp(60px, 40px + 5vw, 110px);
        height: auto;

        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    
        img {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
    
    .franchise__performance {
        display: flex;
        position: absolute;
        
        right: 50%;
        transform: translate(50%);
        bottom: 24px;
        
        width: 90% !important;
        padding: 5%;
        height: fit-content;
        
        border-radius: 12px !important;
        backdrop-filter: blur(25px);

        justify-content: space-between;
        font-family: $ui-font-family;
        
        .franchise__investments, .franchise__paybackPeriod {
            display: flex;
            flex-direction: column;
            gap: 7.5px;

            p {
                color: #ffffff;
                font-size: 0.75rem;
                font-weight: 500;
                line-height: 130%;
            }
    
            span {
                font-size: 1rem;
                font-weight: 700;
                line-height: 140%;
            }
        }
    }
}