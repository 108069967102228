.industryDropdown__container {
      width: 100%;

      .industry__button {
            position: relative;
            outline: none;
            border: none;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 100%;
            width: 100%;
            padding: 10px 20px;
            border-radius: 3.75rem;
            background-color: rgba(220, 220, 220, 0.45);
            transition: all 0.1s ease;
            cursor: pointer;

            &:hover {
                  background-color: rgba(220, 220, 220, 0.65);
            }

            &::before {
                  right: 15px;
                  content: '';
                  position: absolute;
                  transform:  rotate(-45deg);
                  width: 8px;
                  height: 8px;
                  border-left: 2px solid #1f1f1f;
                  border-bottom: 2px solid #1f1f1f;
                  transition: all 0.3s ease;
            }
      }

      .industry__button__expanded {
            background-color: rgba(220, 220, 220, 0.85);

            &::before {
                transform: translateY(30%) rotate(135deg);
            }
        }

      .types__dropdown {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            margin-top: 6px;
      }
}