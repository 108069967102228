@import '../../../styles/vars.scss';

.franchisesYouMayLike {
     width: 100%;
     background: $background-gray;
     @include expend-padding;
     
     .franchisesYouMayLike__content {
          margin-inline: auto;
          max-width: $container-width;
          
          h1 {
               text-align: center;
               font-size: 2.25rem;
               letter-spacing: 0.05em;
          }

          .franchisesYouMayLike__cards {
               display: grid;
               grid-template-columns: auto auto auto;
               justify-content: center;
               gap: clamp(20px, 4vw, 60px);
               margin-top: 2.5rem;

               @media (max-width: 650px) {
                    grid-template-columns: auto auto;

                    & > :last-child {
                         display: none;
                    }
               }
          }
     }
} 