@import '../../../styles/vars.scss';

.modalOverlay {
      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 100%;
      min-height: 100%;

      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      background: rgba(0, 0, 0, 0.9);
      
      z-index: 1000;
      @include fade-in($transition-duration);
      cursor: pointer;
      
      &.closing {
            pointer-events: none;
            @include fade-out($transition-duration);
      }

      .franchiseRequest__container {
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 0.6rem;
            background-color: white;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            
            border: 2px solid $border-color;
            border-radius: 1.5rem;
            
            width: clamp(600px, 500px + 20vw, 850px);
            height: auto;

            padding: 1.2rem;
            cursor: default;
           
            @media (max-width: 768px) {
                  width: clamp(340px, 320px + 8vw, 500px);
            }

            .franchiseRequest__header {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .closeButton {
                        background: none;
                        line-height: 1rem;
                        border: none;
                        color: $primary-color;
                        font-size: 2.5rem;
                        transition: all 0.3s ease;
                        cursor: pointer;
      
                        &:hover {
                              color: $hover-light-color;
                        }
                  }
            }

            .franchiseRequest__body {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  position: relative;
                  width: 100%;
                  height: 100%;
                  gap: 1rem;

                  @media (max-width: 768px) {
                        display: flex;
                        flex-direction: column;
                  }


                  .franchiseImage {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 16 / 9;
                        border-radius: 1.5rem;
                        border: 2px solid $border-color;
                        object-fit: cover;

                        @media (max-width: 768px) {
                              display: none;
                        }
                  }
                  
                  .franchiseForm {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        height: 100%;
                        gap: 0.6rem;
                  }
            }
      }
}