.ErrorDetected {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.04em;
    padding: 0.75rem 1rem;
    background: #ff6464;
    width: fit-content;
    color: #FFFFFF;
    border-radius: 60px;
}