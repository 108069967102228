@import '../../styles/vars.scss';

.nothingFound__container {
      display: flex;
      justify-content: center;

      .nothingFound__content {
            img {
                  margin-inline: auto;
                  width: clamp(150px, 100px + 15vw, 300px);
                  height: clamp(150px, 100px + 15vw, 300px);
            }

            h1 {
                  font-family: $ui-font-family;
                  font-weight: 500 !important;
                  font-size: 1.2rem !important;
                  line-clamp: 160%;
                  text-align: center;
                  color: $primary-color;
            }
      }
}