@import './../../styles/vars.scss';

$header-gap: clamp(10px, 5px + 1.5vw, 30px);

header {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: clamp(60px, 20px + 5vw, 85px);
    z-index: 4;
    background-color: $background-white;
    border-bottom: 2px solid $border-color-light;
    transition: transform $transition-duration ease-in-out;

    &.hidden {
        transform: translateY(-100%);
    }
    
    &.visible {
        transform: translateY(0);
    }

    .header__body {
        display: flex;
        align-items: center;
        margin-inline: auto;
        max-width: $container-width;
        width: 100%;
   
        @media (max-width: 1400px) {
            padding: 0 clamp(10px,  2vw, 20px);
        }
        
        .navigation {
            display: flex;
            align-items: center;
            gap: $header-gap;
            list-style: none;

            .nav__item {
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: $secondary-color;
                text-wrap: nowrap;
                border: none;
                outline: none;
                cursor: pointer;
                transition: color $transition-duration ease-in-out;
    
                &:hover {
                    color: $primary-color;
                }
                
                &.active {
                    color: $primary-color;
                    transition: none;
                }
            }
        }

        .header__start {
            display: flex;
            gap: $header-gap;
            align-items: center;
            padding-right: $header-gap;
            
            .header__burger {
                display: flex;
                align-items: center;
                width: min-content;
                height: 3rem;

                .burger__button {
                    display: flex;
                    background-color: transparent;
                    outline: none;
                    border: none;
                    appearance: none;
                    position: relative;
                    width: 2.2rem;
                    height: 2.2rem;
                    cursor: pointer;
                
                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        height: 2px; 
                        background-color: $golden-olive; 
                        border-radius: 5px;
                        transition: all 0.3s ease;
                    }
                
                    &::before {
                        width: 100%;
                        top: 0.6rem;
                    }
                
                    &::after {
                        width: 1.25rem;
                        bottom: 0.6rem;
                    }

                    &.open {
                        &::before {
                            transform: rotate(45deg);
                            top: 50%;
                            left: 0;
                        }
                
                        &::after {
                            transform: rotate(-45deg);
                            top: 50%;
                            left: 0;
                            width: 100%; 
                        }
                    }
                }

                .burger__menu {
                    position: absolute;
                    left: 0;
                    top: 100%;
                    background-color: white;
                    border-radius: 0 0 20px 20px;
                    width: 100%;
                    padding: 1rem 1.2rem;
            
                    opacity: 0;
                    visibility: hidden;
                    
                    &.open {
                        visibility: visible;
                        @include fade-in($transition-duration);
                    }
            
                    &.close {
                        @include fade-out($transition-duration);
                    }
            
                    .burger__navigation {
                        display: grid;
                        list-style: none;
                        grid-template: 1fr 1fr 1fr / 1fr 1fr;

                        .nav__item {
                            font-size: 1.2rem;
                            color: $golden-olive;
                            font-weight: 500;
                            align-self: center;
                        }

                        .burger__questionnaireButton {
                            grid-column: 1 / -1;

                            button {
                                width: 100%;
                                flex: 1;
                          
                                span {
                                    position: absolute;
                                }
                            }
                        }
                    }
                }
            }
            
            .header__logo {
                position: relative;
                width: clamp(120px, 10vw, 150px);
                margin-right: $header-gap;
                height: 100%;
                border: none;
                outline: none;
                
                @media (max-width: 1024px) {
                    width: clamp(40px, 30px + 1vw, 50px);
                }
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
    
                &:after {
                    content: '';
                    bottom: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 80%;
                    width: 2px;
                    right: calc($header-gap * -1);
                    background: rgba(244, 244, 244, 1);
                    position: absolute;
                }
            }
        }
        
        .header__end {
            display: flex;
            gap: $header-gap;
            justify-content: end;
            width: 100%;
        }

        .adminPage__link {
            position: absolute;
            bottom: 0;
            padding: 0.8rem 1.2rem;
            background-color: $background-white;
            border: 2px solid $border-color-light;
            transform: translateY(100%);
            left: 0;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0.04em;
            color: $secondary-color;
            outline: none;
            cursor: pointer;
            transition: color 0.2s ease-in-out;

            @media (max-width: 768px) {
                left: unset;
                right: 0;
            }

            &:hover {
                background-color: #f3f3f3;
            }

            &.active {
                color: $primary-color;
                transition: none;
            }
        }
    }
}