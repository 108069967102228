.agreement {
    background-color: #FFFFFF;
    color: #565656;
    width: 100%;
    padding: 1.5rem;
    margin-top: 0.875rem;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 130%;

    a {
        position: relative;
        text-decoration: underline;
        color: #565656;
    }
}