@import '../../../styles/vars.scss';

.descriptionBlock {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: clamp(10px, 1vw, 20px);

    h2 {
        font-size: 2rem;
        letter-spacing: 0.05em;
    }

    .descriptionBlock__header {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .descriptionBlock__controllers {
        display: flex;
        gap: clamp(10px, 1vw, 20px);
        justify-content: flex-end;
    }

    .addButton,
    .removeButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: white;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: scale(1.1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        &:active {
            transform: scale(0.95);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
    }

    .addButton {
        background: linear-gradient(135deg, #28a745, #34d058);
    }

    .removeButton {
        background: linear-gradient(135deg, #d32f2f, #ff5252);
    }
}
