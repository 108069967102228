@import '../../styles/vars.scss';

.phoneInputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      &:focus-within .phoneInputButton:not(.error)::before {
            background-color: $focus-border-color-light !important;
      }

      &:hover:not(:focus-within) .phoneInputButton:not(.error)::before {
            background-color: $hover-border-color !important;
      }
}

.phoneInput {
      display: flex !important;
      height: 3rem !important;
      width: 100% !important;
      line-height: 150% !important;
      font-size: 0.875rem !important;
      font-weight: 500;
      color: $primary-color;
      background-color: transparent !important;
      border-radius: 0.75rem !important;
      border: 2px solid $border-color !important;

      transition: border-color ease !important;
      transition-duration: $transition-duration;

      &::placeholder {
          color: $placeholder-color !important;
      }

      &:hover {
          border-color: $hover-border-color !important;
      }

      &:focus {
          border-color: $focus-border-color-light !important;
      }

      &.error {
            border-color: $error-border-color !important;
      }
}

.phoneInputButton {
      position: relative;
      
      border-radius: 0.75rem 0 0 0.75rem !important;
      border: 2px solid transparent !important;
      border-right: none !important;

      background-color: transparent !important;
      
      width: 45px;
      
      transition: all ease !important;
      transition-duration: $transition-duration;

      &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 2px;
            height: 100%;
            background-color: $border-color; 
      }

      &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: calc(100% - 2px);
            z-index: 1;
            border-radius: 0.6rem 0 0 0.6rem !important;
            transition: background-color $transition-duration ease;
      }

      &:hover::after {
            background-color: $border-color-light !important;
      }


      &:focus-within::before {
            background-color: $focus-border-color-light !important;
      }
  
      &:focus-within {
            border-color: $focus-border-color-light !important;
      }

      &.error::before {
            background-color: $error-border-color !important;
      }
}

.react-tel-input .flag-dropdown.open .selected-flag {
      background: none !important;
      border: none !important;
}

.errorIcon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
}

:global {
      .selected-flag {
            z-index: 2;
            overflow: hidden;
            background-color: transparent !important;
            border-radius: 0.6rem 0 0 0.6rem !important;
      }
}