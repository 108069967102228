@import '../../styles/vars.scss';

.card__container {
     display: flex;
     flex-direction: column;
     width: 100%;
     height: auto;
     border-radius: 1rem;
     background: #ffffff;
     border: 1.1px solid $semi-transparent-gray;
     padding: 0.6rem;
     
     @media (min-width: 600px) {
          width: clamp(160px, 140px + 10vw, 260px);
     }

     @media (max-width: 600px) {
          max-width: 200px;
     }

     .card__imagesBox {
          position: relative;
          width: 100%;
          height: clamp(140px, 100px + 10vw, 220px);
          cursor: pointer;

          .franchise__image {
               width: 100%; 
               height: 100%;
               overflow: hidden; 
               
               img {
                    width: 100%;
                    height: 100%;
                    border-radius: 0.75rem;
                    aspect-ratio: 16 / 9;
                    object-fit: cover;
               }
          }

          .userImage__box {
               position: absolute;
               width: 2rem !important;
               height: 2rem !important;
               border-radius: 50% !important;
               bottom: -0.8rem;
               left: 0.8rem;
               overflow: hidden;
               outline: 2px solid white;

               img {
                    width: 100%;
                    min-height: 100%;
                    object-fit: cover;
               }
          }
     }

     .card__info {
          margin-top: 1rem;

          p {
               display: -webkit-box;
               -webkit-line-clamp: 1; 
               -webkit-box-orient: vertical;
               overflow: hidden;
               text-overflow: ellipsis; 
               white-space: normal;
               font-weight: 700;
               font-size: 1.25rem;
          }

          .price_and_time_box {
               display: flex;
               gap: 1rem;
               width: fit-content;
               margin-top: 0.5rem;
               position: relative;
               font-size: 0.75rem;
               font-weight: 500;
               line-height: 100%;
               text-align: left;

               .price {
                    position: relative;

                    span {
                         display: -webkit-box;
                         -webkit-line-clamp: 1; /* количество строк */
                         -webkit-box-orient: vertical; /* вертикальная ориентация */
                         overflow: hidden; /* обрезка текста */
                         text-overflow: ellipsis; /* добавление многоточия */
                         white-space: nowrap; /* чтобы текст не переносился на новую строку */
                    }
                    
                    &::before {
                         content: '';
                         position: absolute;
                         left: 100%;
                         margin-left: 0.5rem;
                         top: 50%;
                         transform: translateY(-50%);
                         width: 1px;
                         height: 0.8rem;
                         background-color: $border-color-light;
                    }
               }

               .time {
                    display: flex;
                    align-items: center;
                    color: $primary-color;

                    .timeIcon {
                         width: 0.7rem;
                         height: 0.7rem;
                         margin-right: 0.1rem;
                    }

                    span {
                         display: -webkit-box;
                         -webkit-line-clamp: 1; /* количество строк */
                         -webkit-box-orient: vertical; /* вертикальная ориентация */
                         overflow: hidden; /* обрезка текста */
                         text-overflow: ellipsis; /* добавление многоточия */
                         white-space: nowrap; /* чтобы текст не переносился на новую строку */
                    }
               }
          }

          .buttons_container {
               display: flex;
               justify-content: space-between;
               align-items: center;
               margin-top: 0.9rem;
               width: 100%;
               border-top: 1px solid $border-color-light;
               padding-top: 1rem;
               font-size: 0.75rem;
               font-weight: 500;
               
               .favorite {
                    svg {
                         fill: $accent-color;
                         
                         path {
                              stroke: $accent-color;
                         }
                    }
               }
               
               .card__icon {
                    width: 1.5rem;
                    height: auto;

                    path {
                         stroke: $icon-color-light;
                    }
               }
               
               .details {
                    line-height: 100%;
                    font-size: 0.75rem;
                    color: $light-gray-color;
               }
          }
     }
}