@import '../../styles/vars.scss';

.contacts__container {
     width: 100%;
     @include container-padding;
     background: $background-gray;

     .contacts__content {
          width: calc(100% - 10vw);
          margin-inline: auto;
          max-width: $container-width;

          .contacts__card {
               padding: 2rem 4rem;
               box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
               border-radius: 24px;
               background: white;
               
               @media (max-width: 768px) {
                    padding: 1rem 2rem;
               }

               h1 {
                    font-size: 2.5rem;
                    letter-spacing: 0.05em;
               }

               .cardInfo__box {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: clamp(20px, 2vw, 40px);
                    margin-top: clamp(20px, 2.5vw, 40px);

                    @media (max-width: 768px) {
                         display: flex;
                         flex-direction: column;
                    }

                    .cardInfo__item {
                         &:nth-child(2) {
                              grid-row: span 2;
                              align-self: end;

                              @media (max-width: 768px) {
                                   align-self: unset;
                              }
                         }
                         
                         span {
                              font-weight: 400;
                              font-size: 1.1rem;
                              line-height: 160%;
                              color: $secondary-color;
                         }
                         
                         p {
                              font-weight: 500;
                              font-size: 1.5rem;
                              line-height: 160%;
                              color: $golden-olive;
                              
                              a {
                                   color: inherit;
                              }
                         }
                    }
               }
          }
     }
}