@import '../../styles/vars.scss';


$franchise_image_width: clamp(150px, 130px + 10vw, 270px);

.franchiseBox__container {
      display: grid;
      grid-template-columns: $franchise_image_width 1fr;
      grid-template-rows: auto 2fr auto;
      position: relative;
      width: 100%;
      height: clamp(160px, 140px + 10vw, 270px);
      background-color: rgba(244, 244, 244, 0.8);
      column-gap: 0.8rem;
      gap: 0.5rem;
      border-radius: 20px;
  
      @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 1rem;
            padding-bottom: 4.5rem;
      }

      .franchiseImage {
            grid-column: 1;
            grid-row: span 3;
            width: $franchise_image_width;
            height: auto;
            min-height: 100%;
            aspect-ratio: 16 / 9;
            min-height: 100% !important;
            border-radius: 20px !important;
            overflow: hidden;
            object-fit: cover;

            @media (max-width: 600px) {
                  order: 2;
                  width: 100%;
                  height: clamp(120px, 100px + 22vw, 220px);
            }
      }

      h2 {
            display: -webkit-box;
            -webkit-line-clamp: 1; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis; 
            white-space: normal;
            font-weight: 600;
            height: min-content;
            font-size: 2rem;
            max-width: 90%;
            line-height: 120%;
            margin-top: 2rem;

            @media (max-width: 600px) {
                  margin: 0;
                  order: 1;
                  -webkit-line-clamp: 3; 
                  font-size: 1.8rem;
                  max-width: 90%;
            }
      }

      .franchise__info {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            width: 100%;

            @media (max-width: 600px) {
                  order: 3;
            }

            .creationTime {
                  display: flex;
                  align-items: center;
                  gap: 0.2rem;

                  span {
                        font-weight: 400;
                        font-size: 1rem;
                        color: #757575;
                  }
            }

            .franchise__descripton {
                  display: -webkit-box;
                  -webkit-line-clamp: 3; 
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis; 
                  white-space: normal;
                  font-weight: 400;
                  font-size: 1.125rem;
                  max-width: 95%;
                  color: $primary-color;

                  @media (max-width: 600px) {
                        max-width: 100%; 
                  }
            }

            h3, h4 {
                  margin: 0;
                  padding: 0;
            }
              
            ul, ol {
                  margin: $ul-ol-margin;
            }
              
            p, li {
                  color: #4d4d4d;
            }
              
            strong {
                  color: $primary-color;
            }
      }

      .totalViews {
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 160%;
            color: $secondary-color;
            margin-bottom: 2rem;

            @media (max-width: 600px) {
                  position: absolute;
                  left: 1rem;
                  bottom: -1rem;
                  border-radius: 60px;
            }
      }
      
      .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 1.5rem;
            border-radius: 20px;
            z-index: 3;

            .overlay__text {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 20px;
                  border-radius: 20px;
                  background: rgba(0, 0, 0, 0.7);

                  span {
                        font-size: 1rem;
                  }
            }
      }

      .toFranchisePage__button {
            position: absolute;
            right: -0.625rem;
            bottom: -0.625rem;
            border: 0.625rem solid white;
            border-radius: 60px;
      }

      .toggle__button {
            display: flex;
            position: absolute;
            top: 2rem;
            right: 2rem;
            cursor: pointer;
            padding: 0.5rem;
            border-radius: 50%;
            transition: all 0.3s ease;

            @media (max-width: 600px) {
                  top: 1rem;
                  right: 1rem;
            }
            
            .icon {
                  width: 2rem;
                  height: auto;
            }

            .favorite__icon {
                  fill: #FFE26D;

                  path {
                        stroke: #FFE26D;
                  }
            }
           
            &:hover {
                  filter: drop-shadow(0 0 10px rgba(255, 226, 109, 0.8));
            }
      }
}