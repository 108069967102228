@import '../../styles/vars.scss';

.searchButton {
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    background-color: transparent;

    .searchIcon > * {
        fill: #C0C0C0;
    }
}


.search__wrapper {
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
        position: absolute;
        top: 0;
        background-color: #ffffff;
    }
    
    &.searchInputActive {
        width: 100%;

        @media (max-width: 768px) {
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 0;
            right: 0;
            z-index: 9999;
            height: auto;
            border-radius: 0 0 1.5rem 1.5rem;
            width: 100%;
        }
    }
    
    .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;  
        width: 100%;
        
        @media (max-width: 768px) {
            display: none;
        }
    
        &.open {
            display: flex;
            gap: 1rem;
            align-self: self-start;
            width: 100%;
            padding: 1rem;
            border-bottom: 2px solid $border-color-light;
        }
    
        .inputField {
            min-width: 175px;
            width: 70%;
            max-width: 100%; 
            
            font-size: 1rem;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.04em;
            
            color: $secondary-color;
            padding: 0.9rem 2.8rem 0.9rem 1.25rem;
    
            border: 2px solid $border-color-light;
            border-radius: 2rem;
            
            outline: none; 
    
            transition: width ease, border-color ease;
            transition-duration: $transition-duration;
    
            &::placeholder {
                color: $secondary-color;
            }
            
            &:hover {
                border-color: $border-color;
            }
    
            &:focus {
                width: 100%;
            }
    
            &.inputFieldActive {
                width: 100%;
                border-radius: 2rem 2rem 0 0;
                border-color: $border-color;
                color: $primary-color;

                @media (max-width: 768px) {
                    border-radius: 2rem;
                }
            }
                    
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    
        .searchIcon {
            position: absolute;
            right: 1rem;
            z-index: 2;

            @media (max-width: 768px) {
                right: 2rem;
            }
        }
    }
    
    .closeButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(220, 220, 220, 0.45);
        border-radius: 100%;
        border: none;
        width: 2rem;
        height: 2rem;
        position: relative;
        cursor: pointer; 

        .closeButton__icon {
            width: 1rem;
            height: auto;
        }
    }
    
    .searchResults {
        position: absolute;
        width: 100%;
        max-height: 0;
        background-color: white;
        border: 2px solid $border-color;
        border-top: 0;
        border-radius: 0 0 0.6rem 0.6rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow-y: auto;

       
    
        @media (max-width: 768px) {
            align-self: self-start;
            position: relative;
            width: 100%;
            border-radius: 0;
            border: none;
            padding: 0.5rem;
        }
    
        &::-webkit-scrollbar {
            width: 0px;
        }
    
        &.active {
            animation: expandAndFadeIn $transition-duration ease-in forwards;
        }
    
        &.hidden {
            animation: fadeOut $transition-duration ease-in forwards;
        }
    }
}


@keyframes expandAndFadeIn  {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 320px;
        opacity: 1;
    }
}

@keyframes collapseAndFadeOut  {
    from {
        max-height: 320px;
    }
    to {
        max-height: 0;
    }
}
